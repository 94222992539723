import { Button } from '@pulse-web-ui/button';
import { styled, typography } from '@pulse-web-ui/theme';
import { WidgetContainer } from '@pulse-web-ui/containers';

export const MoreButton = styled(Button)`
  ${typography.regular14}
  margin-bottom: 16px;
`;

export const RewardHint = styled.p`
  ${typography.regular12}
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const AlertText = styled.p`
  ${typography.regular16}
  margin-top: -4px;
  margin-bottom: 8px;
`;

export const AlertButton = styled(Button)`
  ${typography.regular16}
`;

export const Container = styled(WidgetContainer)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const RestrictionContainer = styled(WidgetContainer)`
  background: transparent;
  padding: 0 24px;
`;
